var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{attrs:{"api-key":_vm.tinyMCE_APIKey,"init":{
          height: 600,
          selector: 'textarea',  // note the comma at the end of the line!
          plugins: [
            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
            'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
            'media', 'table', 'emoticons', 'template', 'help'
          ],
          toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
            'forecolor backcolor emoticons | help',
          menu: {
            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
          },
          menubar: 'favs file edit view insert format tools table help',
        //   content_css: 'css/content.css'
        // plugins: [
        //     'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        //     'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        //     'insertdatetime', 'media', 'table', 'help', 'wordcount'
        // ],
        // toolbar: 'undo redo | blocks | ' +
        // 'bold italic backcolor | alignleft aligncenter ' +
        // 'alignright alignjustify | bullist numlist outdent indent | ' +
        // 'removeformat | help',
        content_style: _vm.contentStyle
        },"initial-value":_vm.content},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }