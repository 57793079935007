<template>
  <div
    :class="
      subtopic && topic && course
        ? 'container mt-5 mb-5'
        : 'container mt-3 mb-5'
    "
  >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="#"><i class="fas fa-home"></i></router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/admin/courses">Kursus</router-link>
        </li>
        <li class="breadcrumb-item" v-if="course">
          <router-link :to="`/admin/courses/${course.id}`">{{
            course.attributes.course_name
          }}</router-link>
        </li>
        <li class="breadcrumb-item" v-if="topic">
          <router-link :to="`/admin/topics/${topic.id}`">{{
            topic.attributes.topic_name
          }}</router-link>
        </li>
        <li class="breadcrumb-item active" v-if="subtopic">
          {{ subtopic.subtopic_name }}
        </li>
      </ol>
    </nav>

    <div class="p-3 bg-white rounded">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="w-100">
          <h2 v-if="subtopic">
            {{ subtopic.subtopic_name }}
          </h2>
        </div>
      </div>
      <div class="row" v-if="subtopicForm">
        <div class="col-8">
          <!-- <v-md-editor
            v-model="subtopicForm.content"
            height="600px"
          ></v-md-editor> -->
          <text-editor
            :html_content="subtopicForm.html_content"
            @set-content="setHTMLContent"
          />
        </div>
        <div class="col-4">
          <div class="col-12 mt-0">
            <div class="container-box">
              <!-- <h5>Tetapan Subtopik</h5> -->
              <h5>Tetapan Kandungan</h5>
              <div
                class="list-group"
                v-if="subtopicForm.files && subtopicForm.files.length > 0"
              >
                <li
                  v-for="(fileOri, i) in subtopicForm.files"
                  :key="fileOri.id"
                  class="list-group-item file-list"
                >
                  <i class="bi bi-file-earmark file-icon"></i>
                  <span class="ms-2">{{ fileOri.name }}</span>
                  <span
                    class="float-end file-icon"
                    @click="deleteFile(fileOri, i)"
                  >
                    <i class="fas fa-trash file-icon"></i>
                  </span>
                </li>
              </div>
              <div class="list-group" v-if="newFiles.length > 0">
                <li
                  v-for="(file, i) in newFiles"
                  :key="file.name + i"
                  class="list-group-item file-list"
                >
                  <i class="bi bi-file-earmark file-icon"></i>
                  <span class="ms-2">{{ file.name }}</span>
                  <span
                    class="float-end file-icon"
                    @click="newFiles.splice(i, 1)"
                  >
                    <i class="fas fa-trash"></i>
                  </span>
                </li>
              </div>
              <div
                class="file-upload-box mt-3"
                v-if="(subtopicForm.files.length + newFiles.length) < 2"
              >
                <div class="file-upload-box-content">
                  <input
                    accept="image/png,image/jpeg,application/pdf,audio/mpeg"
                    type="file"
                    id="relatedFile"
                    lang="es"
                    ref="relatedFile"
                    @change="fileUploaded"
                    hidden
                  />
                  <label for="relatedFile" class="title d-block"
                    >Klik untuk memuat naik</label
                  >
                  <span class="d-block">PDF, JPG, PNG atau MP3 (max 10MB)</span>
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="name" class="control-label mb-1"
                  >Nama Subtopik</label
                >
                <input
                  type="text"
                  v-model="subtopicForm.subtopic_name"
                  class="form-control"
                  placeholder="Sila masukkan nama subtopik"
                />
              </div>
              <div class="form-group mt-3">
                <label for="desc" class="control-label mb-1">Penerangan</label>
                <textarea
                  class="form-control"
                  v-model="subtopicForm.description"
                  rows="3"
                  placeholder="Penerangan Kursus"
                ></textarea>
              </div>
              <div class="form-group mt-3 custom-checkbox">
                <label for="name" class="control-label mb-1">Kuiz</label><br />
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="subtopicForm.has_quiz"
                  id="kuiz"
                />
                <label class="form-check-label ms-3" for="kuiz">
                  {{ subtopicForm.has_quiz ? "Aktif" : "Tidak aktif" }}
                </label>
              </div>

              <div class="pt-4">
                <button
                  class="btn btn-primary"
                  v-if="
              subtopic &&
              (subtopicForm.content != subtopic.content ||
                subtopicForm.html_content != subtopic.html_content ||
                subtopicForm.subtopic_name != subtopic.subtopic_name ||
                subtopicForm.description != subtopic.description ||
                subtopicForm.files.length != subtopic.files.length ||
                subtopicForm.has_quiz != subtopic.has_quiz ||
                newFiles.length > 0)
            "
                  @click="updateSubtopic()"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="warningModal"
      tabindex="-1"
      aria-labelledby="warningModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container-box">
              <h5>Ralat</h5>
              <p>Hanya dua media dibenarkan untuk setiap subtopik.</p>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import TextEditor from './../../components/TextEditor.vue'
import $ from "jquery";

export default {
  data() {
    return {
      files: [],
      newFiles: [],
      subtopic: null,
      subtopicForm: null,
      topic: null,
      course: null,
    };
  },
  components: {
    'text-editor': TextEditor
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get(
        `subtopics/${this.$route.params.subtopicid}?populate=%2A`
      )
      this.subtopic = Object.assign(
        {},
        { id: res.data.data.id },
        res.data.data.attributes
      );
      if (!this.subtopic.content) this.subtopic.content = ""
      this.subtopic.files = this.subtopic.files.data ? this.subtopic.files.data.map((o) => {
        return Object.assign({}, { id: o.id }, o.attributes)}) : []
      this.subtopicForm = { ...this.subtopic };
    },
    setHTMLContent(_val){
      this.subtopicForm.html_content = _val
    },
    fileUploaded() {
      if (this.$refs.relatedFile && this.$refs.relatedFile.files[0]) {
        this.newFiles.push(this.$refs.relatedFile.files[0]);
      }
    },
    async deleteFile(file, i) {
      const res = await API.delete(`upload/files/${file.id}`);
		  if (res.toDelete)
        this.subtopicForm.files.splice(i, 1);
    },
    async uploadFile() {
      let files = [];
      for (let i = 0; i < this.newFiles.length; i++) {
        let formData = new FormData()
        formData.append('files', this.newFiles[i])
        const resFileUpload = await API.post('upload', formData)
        files.push(resFileUpload.data[0].id)
      }

      return files;
    },
    async updateSubtopic() {
      const filesId = await this.uploadFile()
      const res = await API.put(`subtopics/${this.$route.params.subtopicid}`, {
        data: {
          subtopic_name: this.subtopicForm.subtopic_name,
          description: this.subtopicForm.description,
          content: this.subtopicForm.content,
          has_quiz: this.subtopicForm.has_quiz,
          html_content: this.subtopicForm.html_content,
          files: [].concat(filesId, this.subtopicForm.files.map(o => {return o.id}))
        },
      });
      this.newFiles = [];
      await this.fetchData()
    }
  },
  async mounted() {
    await this.fetchData()
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.input-wyswyg {
  width: 70%;

  @media (max-width: 420px) {
    width: 100%;
  }
}
</style>
