<template>
    <div>
        <editor
            :api-key="tinyMCE_APIKey"
            :init="{
              height: 600,
              selector: 'textarea',  // note the comma at the end of the line!
              plugins: [
                'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
                'media', 'table', 'emoticons', 'template', 'help'
              ],
              toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                'forecolor backcolor emoticons | help',
              menu: {
                favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
              },
              menubar: 'favs file edit view insert format tools table help',
            //   content_css: 'css/content.css'
            // plugins: [
            //     'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            //     'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            //     'insertdatetime', 'media', 'table', 'help', 'wordcount'
            // ],
            // toolbar: 'undo redo | blocks | ' +
            // 'bold italic backcolor | alignleft aligncenter ' +
            // 'alignright alignjustify | bullist numlist outdent indent | ' +
            // 'removeformat | help',
            content_style: contentStyle
            }"
            :initial-value="content"
            v-model="content"
          />
    </div>
</template>

<script>
import API from "../utils/API";
import Editor from '@tinymce/tinymce-vue'

export default {
    props: ['html_content'],
    data() {
        return {
            tinyMCE_APIKey: API.getTinyMCE_APIKey(),
            content: this.html_content
        }
    },
    computed:{
        contentStyle(){
            return `
                body { 
                    font-family:Inter, Helvetica, Arial; 
                    font-size:16px;
                }
                
                h1 {
                    font-size: 3rem!important;
                    font-weight: normal;
                }
                h2 {
                    font-size: 2rem!important;
                    font-weight: normal;
                }
                h3 {
                    font-size: 1.875rem!important;
                    font-weight: normal;
                }
                h4 {
                    font-size: 1.5rem !important;
                    font-weight: normal;
                }
                h5 {
                    font-size: 1.2rem !important;
                    font-weight: normal;
                }
                h6 {
                    font-size: 1rem !important;
                    font-weight: normal;
                }

                h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    line-height: 1.2;
                }

                label {
                    font-size: 0.9375rem!important;
                }
                small {
                    font-size: 0.9375rem!important;
                }
                small.extra-small {
                    font-size: 0.775rem !important;
                    line-height: 0.8rem !important;
                }
                small.extra-small-2 {
                    font-size: 0.775rem !important;
                }
                p {
                    font-size: 0.875rem!important;
                    margin-bottom: 0.6rem !important;
                }
                img {
                    width:100%;
                }
            `
        }
    },
    watch: {
        content(){
            this.$emit('set-content', this.content)
        }
    },
    components: {
        'editor': Editor
    },
    mounted() {
        // document.addEventListener('focusin', (e) => {
        //     if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        //         e.stopImmediatePropagation();
        //     }
        // })
    }
}
</script>